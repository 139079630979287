import "./style.scss"
import Icons from "../Icons"
import Status from "../Status"
import { useEffect, useRef, useState } from "react"
import { DateRangePicker } from 'react-date-range';
import { format, formatISO, isExists } from 'date-fns';
import NoResult from "../../assets/svg/NoResults.svg"
import { filedownload, getApiCallNew, postApiCallNew, refreshToken } from "../../service";
import { toast, ToastContainer } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';





const HistoryModal = ({OfferId, setLoading, MerchantName, OfferTitle, OfferStatus, setHistoryDet, OfferDetails}) => {

  pdfMake.vfs = pdfFonts?.pdfMake?.vfs;

  pdfMake.fonts = {
    Inter: {
        normal: `${window.location.origin}/font/Inter-Regular.ttf`,
        bold: `${window.location.origin}/font/Inter-Bold.ttf`,
        italics: `${window.location.origin}/font/Inter-Italic.ttf`,
        bolditalics: `${window.location.origin}/font/Inter-BoldItalic.ttf`
    }
  };
    const navigate = useNavigate();
    const [OffDet,setOffDet] = useState(OfferDetails)
    const [startDate,setStartDate] = useState('')
    const [endDate,setEndDate] = useState('')
    const [toggleCalendar,setToggleCalendar] = useState("")
    const [selectedOptions, setSelectedOptions] = useState([])
    const [sortConfig, setSortConfig] = useState({ key: '', ascending: true });
    const [dateState, setDateState] = useState([
      {
        startDate: new Date(),
        endDate: null,
        key: 'selection'
      }
    ]);

    const[downloadDDOpen,setDownloadDDOpen] = useState(false)
    const[FieldDD,setFieldDD] = useState(false)
    const[searchText,setSearchText] = useState('')
    const handleSearchChange = (event) => {
        setSearchText(event.target.value)
      };
    const [fieldValueList,setFieldValueList] = useState()
    const [selectedValues, setSelectedValues] = useState()
    const prevFieldVal = useRef()
    const FieldDDRef = useRef()
    const SDCalendarRef = useRef() 
    const EDCalendarRef = useRef() 

    const refreshYourToken = async () => {
        const refresh = localStorage.getItem('refreshToken');
        if (!refresh) {
          console.error("No refresh token found");
          return false; // No refresh token, can't refresh
        }
      
        try {
          // Make API call to refresh the token
          const res = await refreshToken(`/auth/refresh-token?refreshToken=${refresh}`);
          
          if (res?.status === 200 || res?.status === 201) {
            const response = await res.json(); // Parse the JSON response
            if (response?.accessToken && response?.refreshToken) {
              // Update tokens in localStorage
              localStorage.setItem("token", response.accessToken);
              localStorage.setItem("refreshToken", response.refreshToken);
              return true; // Indicate success
            }
          } else if (res?.status === 500) {
            localStorage.removeItem("token")
            navigate('/login')
          } else if (res?.status === 401) {
            const response = await res.json();
            console.log("401 error during token refresh:", response);
            if (response?.error === "Session expired due to inactivity.") {
              localStorage.removeItem("token");
              localStorage.removeItem("refreshToken");
              navigate('/login');
            } else if (response?.error === "Unauthorized") {
              console.error("Unauthorized during token refresh");
            } else {
              console.error("Unable to refresh token:", response);
              localStorage.removeItem("token");
              localStorage.removeItem("refreshToken");
              navigate('/login');
            }
            return false; // Indicate failure
          } else {
            const response = await res.json();
            // console.error("Error during token refresh:", response);
            // toast.error(response?.error || "Failed to refresh token");
            return false; // Indicate failure
          }
        } catch (e) {
          // console.error("Error in refreshYourToken:", e);
          // toast.error("Failed to refresh token. Please try again.");
          return false; // Indicate failure
        }
      };

    const historyFilters = () => {
      try{
        getApiCallNew(`/offers/history/filters/${OfferId}`).then(async (res) => {
          
            if (res?.status ===200) {
              let filterList = res.data.field
              setFieldValueList(filterList)
              setSelectedValues(new Set(filterList))
            }
            else if (res?.status===500){
              toast.error(res?.data?.error)
            }
            else if (res?.status===401){
              document.documentElement.style.overflow = 'auto';
                  
              const response = await res.json();
              if (response?.error==="Session expired due to inactivity.") {
                setLoading(false)
                localStorage.removeItem("token")
                localStorage.removeItem("refreshToken");
                navigate('/login')
                setTimeout(() => {
                  toast.error("Session has expired. Please login again.")
                }, 1000);
              } else if (response?.error==="Unauthorized") {
                const isTokenRefresh = await refreshYourToken();
                if (isTokenRefresh) {
                historyFilters()}
              } else {
                setLoading(false)
                localStorage.removeItem("token")
                localStorage.removeItem("refreshToken");
                navigate('/login')
                setTimeout(() => {
                  toast.error(JSON.stringify(response?.access_denied_reason))
                  toast.error(JSON.stringify(response?.error))
                }, 1000);
              }
                      
            }
            else {
              toast.error(res?.error)
            }
          })
        }
        catch (err) {
          console.log(err)
        }
    }
    useEffect(()=>{
       historyFilters()
    },[]);

    function formatDate(dateString) {
      const date = new Date(dateString);
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const day = String(date.getDate()).padStart(2, '0');
      return `${year}-${month}-${day}`;
  }

  const formatDateTime = (dateTimeString) => {
    const [datePart, timePart] = dateTimeString.split(' ');
    const [year, month, day] = datePart.split('-');
    const [hour, minute] = timePart.split(':');
    const formattedDate = `${day}-${month}-${year % 100}`;
    const hour12 = hour % 12 || 12;
    const period = hour < 12 ? 'AM' : 'PM';
    return `${formattedDate} ${hour12}:${minute} ${period}`;
  };

  
 
    
  useEffect(() => {
    
    const fetchOfferDetails = async () => {
      try {
        setLoading(true);
        let params = {
          timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
          filters: {
            ...(startDate && { "fromDate": formatDate(startDate) }),
            ...(endDate && { "toDate": formatDate(endDate) }),
            ...(selectedValues && { "field": [...selectedValues] })
          }
        }
          if( fieldValueList?.length - selectedValues?.size  >= 1)
            params.filters.field = params.filters.field.filter((item)=>item !== "All")
          

        const res = await postApiCallNew(`/offers/history/${OfferId}`, params);
        const response = await res.json();
        if (res.status === 200) {
          response.offerHistoryList = response.offerHistoryList.map((item, index) => ({
            id: index + 1,
            ...item
          }));
          setOffDet(response.offerHistoryList);
        } else if (res.status === 500) {
          toast.error(response?.error);
        } else if (res?.status===401){
          document.documentElement.style.overflow = 'auto';
                  
          const response = await res.json();
          if (response?.error==="Session expired due to inactivity.") {
            setLoading(false)
            localStorage.removeItem("token")
            localStorage.removeItem("refreshToken");
            navigate('/login')
            setTimeout(() => {
              toast.error("Session has expired. Please login again.")
            }, 1000);
          } else if (response?.error==="Unauthorized") {
            const isTokenRefresh = await refreshYourToken();
            if (isTokenRefresh) {
            fetchOfferDetails()}
          } else {
            setLoading(false)
            localStorage.removeItem("token")
            localStorage.removeItem("refreshToken");
            navigate('/login')
            setTimeout(() => {
              toast.error(JSON.stringify(response?.access_denied_reason))
              toast.error(JSON.stringify(response?.error))
            }, 1000);
          }
                  
        } else {
          toast.error(res.error);
        }
      } catch (err) {
        console.error(err);
        toast.error("Failed to fetch offer details. Please try again.");
      } finally {
        setLoading(false);
      }
    };
      if(startDate !== '' || endDate !== '' || prevFieldVal.current)
        fetchOfferDetails();
      prevFieldVal.current = selectedValues;
  }, [startDate, endDate, selectedValues]);
  
    useEffect(()=>{
      const handleClickOutside = (event) =>{
        if (
          FieldDDRef.current &&
          !FieldDDRef.current.contains(event.target) &&
          !document.querySelector('.FilterItem')?.contains(event.target)
          ) {
          setFieldDD(false);
          }      
        if (
          SDCalendarRef.current &&
          !SDCalendarRef.current.contains(event.target) &&
          EDCalendarRef.current &&
          !EDCalendarRef.current.contains(event.target)
        ) {
          setToggleCalendar('');          
          }
    
      }

      document.addEventListener('mousedown', handleClickOutside);
  
      return () => {
          document.removeEventListener('mousedown', handleClickOutside);
      };
    },[])


    
    const handleFieldCheckboxChange = (event) => {
      const value = event.target.value;
      if (!selectedValues.has(value)) {
        const newSelectedValues = new Set(selectedValues);
        newSelectedValues.add(value);
        setSelectedValues(newSelectedValues);
      } else {
        const newSelectedValues = new Set(selectedValues);
        newSelectedValues.delete(value);
        setSelectedValues(newSelectedValues);
      }        
    }
    
    const handleOnchangeDate = (item,value) => {
      let cd = dateState[0].startDate.getTime()
      let ed = new Date(value.endDate).getTime()
      let selectedDate ;
      
      if(cd<ed)
        selectedDate = format(new Date(value.endDate),"dd MMM yyyy")
      else
        selectedDate = format(new Date(value.startDate),"dd MMM yyyy")
      if(item==="Start"){
        setStartDate(selectedDate)
        setEndDate('')
      }
      else if(item==="End"){
        setEndDate(selectedDate)
      }
      setToggleCalendar('')
      setDateState([value])
    }

    const handleFieldSelectAll =() =>{
      const filteredItems = fieldValueList.filter(item => 
        item.toLowerCase().includes(searchText.toLowerCase())
    );
      if (!isAllSelected()) {
        const newSelectedValues = new Set(selectedValues);
        filteredItems.forEach(item => newSelectedValues.add(item));
        setSelectedValues(newSelectedValues); 
      } else {
          const newSelectedValues = new Set(selectedValues);
          filteredItems.forEach(item => newSelectedValues.delete(item));
          setSelectedValues(newSelectedValues);
      }
    }  
    let tableHeader = [
        { label: 'Date & Time', value: 'actionDateTime' },
        { label: 'User', value: 'userName' },
        { label: 'Field', value: 'field' },
        { label: 'Action', value: 'action' },
        { label: 'Previous', value: 'previousValue' },
        { label: 'New', value: 'newValue' },
        { label: 'Comments/ Review', value: 'comments' }
      ];

    function handleDownloadCSV(id){
      try{
        filedownload(`/offers/history/download/${id}`).then(async (res) => {
      
          if (res?.status === 200) {
            
          }
          else if (res?.status===500){
            toast.error(res?.data?.error)
          }
          else if (res?.status===401){
            document.documentElement.style.overflow = 'auto';
                    
            const response = await res.json();
            if (response?.error==="Session expired due to inactivity.") {
              setLoading(false)
              localStorage.removeItem("token")
              localStorage.removeItem("refreshToken");
              navigate('/login')
              setTimeout(() => {
                toast.error("Session has expired. Please login again.")
              }, 1000);
            } else if (response?.error==="Unauthorized") {
              const isTokenRefresh = await refreshYourToken();
              if (isTokenRefresh) {
              handleDownloadCSV(id)}
            } else {
              setLoading(false)
              localStorage.removeItem("token")
              localStorage.removeItem("refreshToken");
              navigate('/login')
              setTimeout(() => {
                toast.error(JSON.stringify(response?.access_denied_reason))
                toast.error(JSON.stringify(response?.error))
              }, 1000);
            }
                    
          }
          else {
            toast.error(res?.error)
          }
          })
        }
        catch (err) {
          console.log(err)
        } 
    }

    const handleSelectAll =() => {
      if(OffDet?.length === selectedOptions.length){
        setSelectedOptions([])
      }
      else{
        let ss = OffDet?.map((item)=>item.id)
        setSelectedOptions(ss)
      }
    }
    const handleCheckboxChange = (option) => {
      if(selectedOptions.includes(option)){
        setSelectedOptions(selectedOptions.filter(item=>item!==option))
      }
      else{
        setSelectedOptions([...selectedOptions,option])
      }
      
    }   

    function getStatusStyles(statusText) {
      let textColor = 'black'; 
      let backgroundColor = 'white';
      console.log(statusText.trim(),"POI")
      switch (statusText.trim()) {
        case "Live":
          textColor = "#14532D";
          backgroundColor = "#F0FDF4";
          break;
        case "Rejected":
          textColor = "#DC2626";
          backgroundColor = "#FEF2F2";
          break;
        case "Draft":
          textColor = "#374151";
          backgroundColor = "#F3F4F6";
          break;
        case "Requested to Pause":
        case "Requested to Resume":
          textColor = "#B010B6";
          backgroundColor = "#FFE8FB";
          break;
        case "Paused":
          textColor = "#CFAF0F";
          backgroundColor = "#FEFFE8";
          break;
        case "Expired":
          textColor = "#CC5120";
          backgroundColor = "#FFF3E8";
          break;
        case "Scheduled":
          textColor = "#002D8E";
          backgroundColor = "#E6EDFD";
          break;
        case "Resume In Progress":
          textColor = "#001E5E";
          backgroundColor = "#C3E7FE";
          break;
        case "Pause In Progress":
          textColor = "#001E5E";
          backgroundColor = "#C3E7FE";
          break;
        case "Pending for Approval":
          textColor = "#D97706";
          backgroundColor = "#FFFBEB";
          break;
        default:
          textColor = "black"; 
          backgroundColor = "red"; 
      }
    
      return { textColor, backgroundColor };
    }
    const parseHtmlTable = (htmlString) => {
      const tempDiv = document.createElement('div');
      tempDiv.innerHTML = htmlString;
    
      const content = [];
    
      // Function to handle child nodes recursively
      const handleChildNodes = (child) => {
        if (child.nodeType === Node.TEXT_NODE) {
          const textContent = child.textContent.replace(/\u00A0/g, ' ').trim();
          if (textContent.length > 0) {
            return [{ text: textContent }];
          }
        } else if (child.nodeName === 'I') {
          return handleChildNodes(child.firstChild).map(item => ({
            ...item,
            italics: true
          }));
        } else if (child.nodeName === 'STRONG' || child.nodeName === 'B') {
          return handleChildNodes(child.firstChild).map(item => ({
            ...item,
            bold: true
          }));
        } else if (child.nodeName === 'A') {
          const href = child.getAttribute('href');
          const textContent = child.textContent.replace(/\u00A0/g, ' ').trim();
          if (textContent.length > 0) {
            return [{ text: textContent, link: href, color: '#0000EE' }];
          }
        } else {
          // Recursively handle other child nodes
          return Array.from(child.childNodes).flatMap(handleChildNodes);
        }
        return [];
      };
    
      // Iterate over each child node of the temporary div
      Array.from(tempDiv.childNodes).forEach((node) => {
        // If the node is a table, extract its data
        if (node.nodeName === 'FIGURE' && node.classList.contains('table')) {
          const table = node.querySelector('table');
          if (table) {
            const rows = Array.from(table.querySelectorAll('tr')).map(row => {
              const cells = Array.from(row.querySelectorAll('td, th')).map(cell => {
                const text = cell.innerHTML.replace(/&nbsp;/g, '').trim(); // Replace non-breaking spaces
                return { text: text || '', style: 'tableCell',  font: 'Inter'  }; // Ensure non-empty text
              });
              return cells; // Return the row of cells
            });
    
            // Add the table to content if rows exist
            if (rows.length > 0) {
              content.push({ table: { body: rows } });
            }
          }
        } 
        // If the node is a paragraph or list item, capture its text with styles
        else if (node.nodeType === Node.ELEMENT_NODE && (node.nodeName === 'P' || node.nodeName === 'LI')) {
          const styledText = handleChildNodes(node);
          // Push the styled text if not empty
          if (styledText.length > 0) {
            content.push({
              text: styledText.map(item => item.text || '').join(''), // Join text
              italics: styledText.some(item => item.italics), // Check for italics
              font: 'Inter' ,
              bold: styledText.some(item => item.bold), // Check for bold
              link: styledText.find(item => item.link)?.link, // Get link if available
              color: styledText.find(item => item.color)?.color, // Get color if available
              margin: [0, 5, 0, 5],
              style: 'textContent'
            });
          }
        } 
        // If the node is an unordered list
        else if (node.nodeName === 'UL') {
          const listItems = Array.from(node.querySelectorAll('li')).map(li => {
            const styledText = handleChildNodes(li);
            return {
              text: `• ${styledText.map(item => item.text || '').join('')}`, // Bullet point
              margin: [0, 5, 0, 5],
              style: 'textContent',
              font: 'Inter' ,
              italics: styledText.some(item => item.italics),
              bold: styledText.some(item => item.bold),
              link: styledText.find(item => item.link)?.link, // Get link if available
              color: styledText.find(item => item.color)?.color // Get color if available
            };
          });
          content.push(...listItems);
        } 
        // If the node is an ordered list
        else if (node.nodeName === 'OL') {
          const listItems = Array.from(node.querySelectorAll('li')).map((li, index) => {
            const styledText = handleChildNodes(li);
            return {
              text: `${index + 1}. ${styledText.map(item => item.text || '').join('')}`, // Numbering
              margin: [0, 5, 0, 5],
              font: 'Inter' ,
              style: 'textContent',
              italics: styledText.some(item => item.italics),
              bold: styledText.some(item => item.bold),
              link: styledText.find(item => item.link)?.link, // Get link if available
              color: styledText.find(item => item.color)?.color // Get color if available
            };
          });
          content.push(...listItems);
        }
      });
    
      // Return the structured content or an empty text item if no valid content found
      return content.length > 0 ? content : [{ text: htmlString, style: 'textContent' }];
    };
    

    const generatePDF = () => {
      const { textColor, backgroundColor } = getStatusStyles(OfferStatus); 
           
      const docDefinition = {
        pageOrientation: 'landscape', 
        content: [
          {
            text: `Offer History - ${OfferId}`,
            style: 'header',
            font: 'Inter' 
          },
          {
            table: {
              widths: ['*', 'auto'], 
              body: [
                [
                  {
                    text: `${MerchantName} - ${OfferTitle}`,
                    style: 'subheader',
                    font: 'Inter' ,
                  },
                  {
                    text: OfferStatus,
                    style: 'status',
                    alignment: 'center',
                    width: 'auto',
                    color: textColor,
                    fillColor: backgroundColor,
                    font: 'Inter' ,
                  },
                ],
              ],
            },
            layout: 'noBorders',
            margin: [0, 0, 0, 20],
          },
          {
            table: {
              widths: ['18%', '12%', '12%', '12%', '14%', '14%', '18%'],
              body: [
                [
                  { text: 'Date', style: 'tableHeaderone', font: 'Inter'  },
                  { text: 'User', style: 'tableHeader', font: 'Inter'  },
                  { text: 'Field', style: 'tableHeader', font: 'Inter'  },
                  { text: 'Action', style: 'tableHeader', font: 'Inter'  },
                  { text: 'Previous', style: 'tableHeader', font: 'Inter'  },
                  { text: 'New', style: 'tableHeader', font: 'Inter'  },
                  { text: 'Comments/Review', style: 'tableHeader', font: 'Inter'  },
                ],
                ...OffDet.map(offerDetails => [
                  { text: formatDateTime(offerDetails.actionDateTime), style: 'tableCellone', font: 'Inter'  },
                  { text: offerDetails.userName, style: 'tableCell', font: 'Inter'  },
                  { text: offerDetails.field, style: 'tableCell', font: 'Inter'  },
                  { text: offerDetails.action, style: 'tableCell', font: 'Inter'  },
                  { 
                    stack: parseHtmlTable(offerDetails?.previousValue),
                    style: 'tableCell',
                    alignment: 'left',
                    pageBreak: 'avoid',
                    font: 'Inter' 
                  },
                  { 
                    stack: parseHtmlTable(offerDetails?.newValue),
                    style: 'tableCell',
                    alignment: 'left',
                    pageBreak: 'avoid',
                    font: 'Inter' 
                  },
                  { text: offerDetails.comments, style: 'tableCell', font: 'Inter'  },
                ]),
              ],
            },
            layout: { hLineWidth: (i, node) => (i === 0 || i === 1 ? 0 : 1),
              vLineWidth: () => 0,
              hLineColor: () => '#E5E7EB',
              paddingLeft: () => 0,
              paddingRight: () => 0,
              paddingTop: () => 5,
              paddingBottom: () => 5,},
          },
        ],
        styles: {
          header: {
            fontSize: 26,
            fontWeight:500,
            lineHeight: 1.5,
            margin: [0, 20, 0, 10],
            font: 'Inter' 
          },
          subheader: {
            fontSize: 16,
            margin: [0, 0, 0, 0],
            font: 'Inter' 
          },
          status: {
            fontSize: 14,
            borderRadius: 40, 
            padding: [2, 16], 
            margin: [16, 0,16, 0],
            font: 'Inter' 
          },
          boldText: {
            bold:true,
            font: 'Inter' 
          },
          tableHeader: {
            bold: true,
            fontSize: 12,
            color: 'black',
            fillColor: '#eeeeee',
            margin: [0, 7, 0, 7],
            font: 'Inter' 
          },
          tableHeaderone: {
            bold: true,
            fontSize: 12,
            color: 'black',
            fillColor: '#eeeeee',
            margin: [16, 7, 0, 7],
            font: 'Inter' 
          },
          tableCell: {
            margin: [0, 8,8,0],
            font: 'Inter' 

          },
          tableCellone: {
            margin: [16,8,8,0],
            font: 'Inter' 
          },
        },
      };
    
      pdfMake.createPdf(docDefinition).download(`Offer_History_${OfferId}.pdf`);
  };
    const sortTable = (key) => {
      const newOrder = sortConfig.key === key ? !sortConfig.ascending : true;
      setSortConfig({ key, ascending: newOrder });
      const sortedData = [...OffDet].sort((a, b) => {
        let valueA = a[key];
        let valueB = b[key];
        if (key === 'DateNTime') {
          valueA = new Date(valueA);
          valueB = new Date(valueB);
        }
        if (valueA === undefined || valueB === undefined) {
          console.error(`Cannot compare: ${valueA}, ${valueB}`);
          return 0;
        }
        if (typeof valueA === 'string' && typeof valueB === 'string') {
          return newOrder
            ? valueA.localeCompare(valueB) 
            : valueB.localeCompare(valueA);
        }
        return newOrder ? valueA - valueB : valueB - valueA;
      });
      setOffDet(sortedData);
    };
   
    function isSubset(list1, list2) {
      return list1.some(item => list2.has(item));
    }
    function isSameset(list1, list2) {
      return list1.filter(item => item.trim() !== "All").every(item => list2.has(item));
  }
  
  
    function isAllSelected(){
      const filteredItems = fieldValueList.filter(option => 
        option.toLowerCase().includes(searchText.toLowerCase())
    );
    return filteredItems.every(item => selectedValues.has(item)); 
    }    

    const filteredOptions = fieldValueList?.filter(option =>
      option.trim().toLowerCase() !== "all" && option.toLowerCase().includes(searchText.toLowerCase())
    ) || [];
    
    const optionsToDisplay = filteredOptions.length > 0 ? ["All", ...filteredOptions] : filteredOptions;
    
    return <div className="HistoryModal">
        <div className="Maindiv" id="pdf-content">
            <div className="Heading"><div>Offer History - {OfferId}</div>
                <div className="RightIcons">
                    {OffDet?.length >= 1 && <div style={{position: "relative"}} onClick={()=>{setDownloadDDOpen(!downloadDDOpen)}}>
                        <Icons name="download" width="32" height="32" />
                        {downloadDDOpen && 
                        <div className={`actions-dropdownlist`}>
                            <button className='action-new' onClick={() => {
                                handleDownloadCSV(OfferId);
                            }}>
                                Download as .CSV
                            </button>
                            <button className='action-new' onClick={() => {
                                generatePDF();
                            }}>
                                Download as .Pdf
                            </button>
                        </div>
                        } 
                     </div>}
                    <div onClick={()=>{setHistoryDet({});document.documentElement.style.overflow = 'auto'; }}><Icons name="cancel-grey"  width="32" height="32" /></div>
                </div>
            </div>
            <div className="Details">
            <div className="MerhantDet  pdf-content">
                {MerchantName} - {OfferTitle}
                <div className="OfferStatus">
                {(() => {
                    switch(OfferStatus?.trim()) {
                      case "Live":
                        return <Status text="Live" textColor="#14532D" bgColor="#F0FDF4"/>;
                      case "Rejected":
                        return <Status text="Rejected" textColor="#DC2626" bgColor="#FEF2F2"/>;
                      case "Draft":
                        return <Status text="Draft" textColor="#374151" bgColor="#F3F4F6"/>;
                      case "Requested to Pause":
                        return <Status text="Requested to Pause" textColor="#B010B6" bgColor="#FFE8FB"/>;
                      case "Requested to Resume":
                        return <Status text="Requested to Resume" textColor="#B010B6" bgColor="#FFE8FB"/>;
                      case "Paused":
                        return <Status text="Paused" textColor="#CFAF0F" bgColor="#FEFFE8"/>;
                      case "Expired":
                        return <Status text="Expired" textColor="#CC5120" bgColor="#FFF3E8"/>;
                      case "Scheduled":
                        return <Status text="Scheduled" textColor="#002D8E" bgColor="#E6EDFD" />;
                      case "Resume In Progress":
                        return <Status text="Resume In Progress" textColor="#001E5E" bgColor="#C3E7FE"/>;
                      case "Pause In Progress":
                        return <Status text="Pause In Progress" textColor="#001E5E" bgColor="#C3E7FE" />;
                      case "Pending for Approval":
                        return <Status text="Pending for Approval" textColor="#D97706" bgColor="#FFFBEB"/>;
                      default:
                        return <Status text={OfferStatus} textColor="#CFAF0F" bgColor="#FFF3E8"/>;
                    }
                  })()}
                </div>
            </div>
            {OfferDetails?.length >= 1 && <div className="Filters">
                  <div className="TimeStamp">
                    <div>Date : </div>
                    <div className="calendar" ><Icons name="calendar" width="24" height="24" /> </div>
                    <div ref={SDCalendarRef} className={`dateVal ${startDate ? 'act' : ''}`} onClick={(e)=>{
                        setToggleCalendar("Start");
                      }}>{startDate ? <>{startDate}</> :"Select Date"}
                      {toggleCalendar === "Start" && <div style={{position:"absolute", right:"0", zIndex:"50"}}>
                        <div >
                        <DateRangePicker
                          editableDateInputs={true}
                          onChange={item => handleOnchangeDate("Start",item.selection)}
                          focusedRange={[0,1]}
                          moveRangeOnFirstSelection={false}
                          ranges={dateState}
                          showSelectionPreview={true}
                          months={1}
                          direction="horizontal"
                        />
                        </div>
                      </div>}
                    </div>
                    <div className="calendar-arrow"><Icons name="calendar-arrow" width="24" height="24" /></div> 
                    <div  ref={EDCalendarRef} className={`dateVal ${endDate ? 'act' : ''}`} onClick={(e)=>{
                        setToggleCalendar("End");
                      }}>{endDate ? <>{endDate}</> :"Select Date"}
                      {toggleCalendar==="End" && <div style={{position:"absolute", right:"0", zIndex:"50"}}>
                        <div>
                        <DateRangePicker
                          editableDateInputs={true}
                          onChange={item => handleOnchangeDate("End",item.selection)}
                          focusedRange={[0,1]}
                          moveRangeOnFirstSelection={false}
                          ranges={dateState}
                          showSelectionPreview={true}
                          months={1}
                          minDate={startDate ? new Date(startDate) :new Date("01 Jan 1924")}
                          direction="horizontal"
                        /></div>
                      </div>}
                    </div>
                  </div>
                  <div className="Separator">|</div>
                  <div className="FilterItem" onClick={(e)=>{e.stopPropagation();setFieldDD(!FieldDD)}}>Field & Action : All <Icons name="nav-arrow-down" height="20" width="20" /> </div>

                  <div>
                    {FieldDD &&
                    <div ref={FieldDDRef} className="FieldDD">
                      <div className='drop-search'>
                        <div><Icons
                          name="search"
                          width="18"
                          height="18"
                          fill="#6B7280"
                        /></div>
                        <div className='dropdown-inputbox'>
                        <input 
                            type="text" 
                            placeholder="Search..." 
                            value={searchText}
                            onChange={(e) => handleSearchChange(e)}
                            className="dropdown-search"
                          />
                        </div>
                      </div>
                      <div className='hr-line ml-10 mb-24'></div>
                    <div className="dropdown custom-scrollbar">
                      <div className='val-dropdown'>
                      {optionsToDisplay
                        .map(option => (
                          <div key={option}>
                            {option.trim() === "All" ? (
                              <label className={`container`}>
                                <input
                                  type="checkbox"
                                  checked={selectedValues.size >= 1 && isSubset(
                                    fieldValueList.filter(item => item.trim().toLowerCase() !== "all" && item.toLowerCase().includes(searchText.toLowerCase())),
                                    selectedValues
                                  )}
                                  onChange={() => handleFieldSelectAll()}
                                />
                                <span className={`${selectedValues.size >= 1 && isSameset(
                                  fieldValueList.filter(item => item.trim().toLowerCase() !== "all" && item.toLowerCase().includes(searchText.toLowerCase())),
                                  selectedValues
                                ) ? "checkmark1" : "checkmark2"}`}></span>
                                All
                              </label>
                            ) : (
                              <label className="container">
                                <input
                                  type="checkbox"
                                  value={option}
                                  checked={selectedValues?.has(option)}
                                  onChange={(e) => handleFieldCheckboxChange(e)}
                                />
                                <span className="checkmark1"></span>
                                {option}
                              </label>
                            )}
                          </div>
                        ))}

                      </div>
                    </div>
                  </div>}
                </div>
              </div>}
            {OfferDetails?.length >= 1 ? <div className="HistoryTable">
              {OffDet?.length === 0 ? 
                      <div className="NoResults">
                      <img src={NoResult}/>
                      <div> No offer history available.<br />
                This offer was created outside of the Offer Management Platform </div>
                    </div>
                      :
                <table>
                  <thead>
                      <td>
                      <label className="container">
                          <input
                          type="checkbox"
                          onChange={handleSelectAll}
                          checked={selectedOptions?.length >= 1}
                          />
                          <span className={selectedOptions?.length === OffDet?.length ? "checkmark1" : "checkmark2"}></span>
                      </label>
                      </td>
                      {tableHeader && tableHeader.map(
                      ({ label, value }) => {
                          return (<td onClick={()=>{
                            
                              {
                                sortTable(value)
                              }
                          }}>
                          <div>
                              {label}
                              {
                              <span className='sort' >
                                  <div><Icons name="arrow-up" width={6} height={8} /></div>
                                  <div><Icons name="arrow-down" width={6} height={8} /></div>
                              </span>
                              }
                          </div>
                          </td>)
                      }
                      )}
                  </thead>
                  <tbody>
                      {OffDet.length >= 1 && OffDet?.map((offerDetails) => {
                      return (<tr>
                          <td style={{width:"4%"}}>
                          <label className="container">
                          <input
                              type="checkbox"
                              checked={selectedOptions?.includes(offerDetails.id)}
                              onChange={() => {handleCheckboxChange(offerDetails.id)}}
                          />
                          <span className="checkmark1"></span>
                          </label>
                          </td>
                          <td style={{width:"14%"}}>{formatDateTime(offerDetails.actionDateTime)}</td>
                          <td style={{width:"11%"}}>{offerDetails.userName}</td>
                          <td style={{width:"11%"}}>{offerDetails.field}</td>
                          <td style={{width:"14%"}}>{offerDetails.action}</td>
                          <td style={{width:"14%",wordBreak:'break-word'}}><div dangerouslySetInnerHTML={{ __html: offerDetails?.previousValue }} /></td>
                          <td style={{width:"14%",wordBreak:'break-word'}}><div dangerouslySetInnerHTML={{ __html: offerDetails?.newValue }} /></td>
                          <td style={{width:"18%"}}>{offerDetails.comments}</td>
                      </tr>)
                      })}
                  </tbody>    
              </table>
              }
            </div> : 
              <div className="NoResults">
                <img src={NoResult}/>
                <div> No offer history available.<br />
                This offer was created outside of the Offer Management Platform </div>
              </div>}
          </div>
        </div>
    </div>
}

export default HistoryModal